<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <h5>{{ inputValues[1] ? inputValues[1].value : '' }}</h5>
      <p>{{ inputValues[2] ? inputValues[2].value : '' }}</p>
      <h5 class="mb-1">
        Forms
      </h5>
      <div
        v-for="(field, key) in inputValues[3].value"
        :key="key"
      >
        <span>{{ formName(field) }}</span>
        <hr
          v-if="key < (inputValues[3].value.length - 1)"
          class="dividerHR"
        >
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    formOptions: {
      type: [Array, null],
      default: null,
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {
    formName(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        return `${form.name} (${form.formNumber}) (${form.revision})`
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
</style>
